import React from "react";
import Img from "gatsby-image";
import document from "./contentful";
import { Player, ControlBar, BigPlayButton } from "video-react";

const Video = ({ url }) => (
  <Player playsInline src={url} autoPlay={true} muted={true} loop={true}>
    <BigPlayButton position="center" />
    <ControlBar className="my-class" disableCompletely={true} />
  </Player>
);

const SubImages = ({ media, color }) => {
  return (
    <div className="">
      {media.map((m, i) => (
        <div key={`media-${i}`}>
          {m.file.contentType.startsWith("video") ? (
            <Video url={m.file.url} />
          ) : (
            <Img fluid={m.fluid} backgroundColor={`#${color}`} />
          )}
        </div>
      ))}
    </div>
  );
};

export default ({ project }) => {
  const doc = JSON.parse(project.description.raw);

  return (
    <section className="mb-24 content">
      <div className=" leading-normal">
        <SubImages media={project.media} color={project.color} />

        <div className="md:flex mt-1">
          <div className="w-full md:w-6/12 md:pr-32">
            <h2 className="uppercase mb-0 text-3xl md:text-5xl ">
              <span className="">{project.title}</span>
            </h2>
            <ul className="mt-4 text-base md:text-base">
              {project.credits.map((c, i) => (
                <li key={`credit${i}`} className="my-0">
                  {c}
                </li>
              ))}
              <li>Year: {project.year}</li>
            </ul>
          </div>
          <div className="w-full text-base sm:text-xl md:w-6/12">
            {document({ doc: doc, color: `#${project.color}` })}
          </div>
        </div>
      </div>
    </section>
  );
};
